@import "./variables";

$sticky-column-z-index: 12;
$sticky-header-z-index: 13;
$scroller-extra-margin: 12px;
$odd-rows-background-color: #f5f5f5;
$even-rows-background-color: #fff;
$header-height: 78px;
$selected-row-background-color: rgba(116, 178, 54, 0.3);
$selected-row-background-color-no-opacity: #D5E8C2;
$selected-hovered-row-background-color-no-opacity: #C6DFC0;

.table-container {
  min-height: 200px;
  position: relative;
  .simplebar-100percent-width {
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    &.freezeFirstColumn table > * > tr > th:first-child,
    &.freezeFirstColumn table > * > tr > td:first-child,
    .pinned {
      position: sticky;
      left: 0;
      z-index: $sticky-column-z-index;
      max-width: 50vw;
    }
    .last-left-pinned {
      @extend .table-first-column-shadow;
    }
    &.with-footer {
      max-height: calc(100% - 51px);
    }
  }
  .os-scrollbar {
    cursor: default;
    pointer-events: auto;
  }
  .os-scrollbar-horizontal {
    margin-left: $scroller-extra-margin;
    margin-right: $scroller-extra-margin;
  }
  .os-scrollbar-vertical {
    margin-top: $header-height + $scroller-extra-margin;
    margin-bottom: $scroller-extra-margin;
  }
  .resizer {
    cursor: col-resize;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    touch-action: none;
    user-select: none;
    width: 2px;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  
  .resizer.isResizing {
    background: var($--main-theme-color);
    opacity: 1;
  }
}

.table-container:hover .dropdown-container { display: flex; }

div#main > div#main-content {
  div.table-view {
    padding: 0 22px;
    margin-bottom: 36px;

    &.has-caption {
      position: relative;
      .table-caption,
      .tabs {
        background-color: white;
        display: inline-block;
        padding: 0 22px;
        line-height: 51px;
        border: $color-border 1px solid;
        height: 51px;
        margin: 0;
        font-size: $font-size-base;
        border-bottom-color: white;
        left: -1px;
      }
      .table-tabs {
        padding: 0;
        height: 49px;
        border: none;
        margin-bottom: $margin-main;

        li {
          float: left;
          padding: 0 22px 0 0;
          vertical-align: middle;
          line-height: 52px;
          height: 52px;
          user-select: none;
          text-align: left;
          cursor: default;
          font-weight: $font-weight-bold;
          transition: background-color $animation-swift-out, border-color $animation-swift-out;
          &:not(.active):hover {
            cursor: pointer;
          }

          &.active,
          &:hover {
            color: $color-brand-light;
            position: relative;
            span {
              display: inline-block;
              position: relative;
            }
            span:after {
              content: " ";
              display: inline-block;
              height: 3px;
              position: absolute;
              margin: 0 auto;
              bottom: 0;
              background: $color-gradient-blue;
              left: 10%;
              right: 10%;
            }
          }
        }
      }
    }
  }
}

table.table {
  &.hub-devices {
    tbody {
      padding: 0 21px;
      tr {
        td {
          &.device_id {
            color: var($--main-theme-color);
          }

          &.buttons {
            button,
            [role="button"] {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

table.table {
  // width: 100%;
  min-width: 100%;
  height: 1px;
  font-size: $font-size-base;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 20px;
  position: relative;
  &.fixed-layout {
    table-layout: fixed;
  }
  tr {
    height: 50px;
    /*line-height: 52px;*/
    transition: background-color $animation-swift-out, color $animation-swift-out;

    @media (max-width: $width-laptop-hidpi) {
      height: 50px;
      /*line-height: 52px;*/
    }
  }

  & > thead {
    position: sticky;
    top: 0;
    z-index: $sticky-header-z-index;
    background-color: #fff;
    & > tr {
      height: $header-height;
      /*line-height: 64px;*/
      & > th {
        position: relative;
        font-size: 18px;
        color: #000;
        cursor: default;
        padding: 26px 50px 26px 15px;
        font-weight: $font-weight-bold;
        vertical-align: middle !important;
        text-align: left;
        border-right: 1px solid #d9d9d9;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        will-change: opacity; // workaround for Safari, this browser optimizes to much process of rendering and header of table was glitched in some cases when data was loaded. This property warn engine that it is volatile thing and have to be carefully re-rendered. It was quite hard to discover that

        &:first-child {
          @extend .table-first-column-shadow;
        }

        &:last-child {
          border-right: none;
          border-top-right-radius: 20px;
        }

        a {
          font-size: $font-size-th;
          color: #000000;
        }

        &.sortable:hover {
          position: relative;
          cursor: pointer;
          .sort-icon {
            display: inline-block;
          }
        }

        &.sortable {
          .sort-icon {
            display: none;
            position: absolute;
            width: 23px;
            height: 23px;
            cursor: pointer;
            background: var($--arrow-down-icon) no-repeat center;
            background-size: 23px;
            margin-left: 10px;
            &.ascending {
              transform: rotate(180deg);
            }
            &.descending, &.ascending {
              display: inline-block;
            }
          }
        }
        
        &.buttons {
          display: none;
          button,
          [role="button"] {
            opacity: 1;
          }
        }

      }
      & > th {        
        &:first-child {
          background: #fff;
          border-top-left-radius: 20px;
        }
      }
    }
  }

  & > thead:after {
    box-shadow: inset 0 9px 5px -5px #dadada;
    position: absolute;
    width: 100%;
    height: 9px;
    z-index: 6;
    content: "";
  }
  & > thead.no-shadow:after {
    display: none;
  }

  & > tbody {
    border: 1px solid #d9d9d9;
    padding: 0 21px;
    & > tr {
      font-size:$font-size-tr;
      cursor: pointer;

      .sticky-right {
        position: sticky;
        right: 0;
        padding: 0;
        max-width: 0;
        overflow: unset;
        border: 0;
        .buttons-wrapper {
          display: flex;
          justify-content: flex-end;
          height: 100%;
          .buttons-background-gradient {
            min-width: 0;
            border-top: var($--main-theme-color) 1px solid;
            border-bottom: var($--main-theme-color) 1px solid;
          }
        }
      }

      &.with-buttons {
        td {
          &:nth-last-child(2) {
            border-right: rgba(0, 0, 0, 0) 1px solid;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }

      &:last-child {
        & > td {
          
          // extra 10 pixels for horizontal scroller?
          // padding-bottom: 22px;


          &:first-child {
            border-bottom-left-radius: 20px;
          }

          &:last-child {
            border-bottom-right-radius: 20px;
          }
        }
        &.with-buttons {
          td {
            &:nth-last-child(2) {
              border-bottom-right-radius: 20px;
            }
          }
        }
      }

      &:nth-child(odd) {
        background: $odd-rows-background-color;
        .buttons-background {
          background: $odd-rows-background-color;
        }
        .buttons-background-gradient {
          background: linear-gradient(to right, rgba(245, 245, 245, 0) 0px, var($--table-hover-color-odd) 100%);
        }

        td {        
          &:first-child {
            background: $odd-rows-background-color;
            border-left: rgba(0, 0, 0, 0) 1px solid;
          }
        }
        .data-loading {
          background-color: $odd-rows-background-color;
        }
      }

      &:nth-child(even) {
        background: $even-rows-background-color;
        .buttons-background {
          background: $even-rows-background-color;
        }
        .buttons-background-gradient {
          background: linear-gradient(to right, rgba(245, 245, 245, 0) 0px, var($--table-hover-color-even) 100%);
        }

        td {        
          &:first-child {
            background: $even-rows-background-color;
            border-left: rgba(0, 0, 0, 0) 1px solid;
          }
        }
        .data-loading {
          background-color: $even-rows-background-color;
        }
      }

      &:hover {
        &:nth-child(odd) {
          td {        
            &:first-child {
              border-left: var($--main-theme-color) 1px solid;
              background: var($--table-hover-color-odd);
            }
          }
        }
  
        &:nth-child(even) {
          td {        
            &:first-child {
              border-left: var($--main-theme-color) 1px solid;
              background: var($--table-hover-color-even);
            }
          }
        }

        &.with-buttons {
          td {
            &:nth-last-child(2) {
              border-right: var($--main-theme-color) 1px solid;
            }
            &:last-of-type {
              border: 0;
            }
          }
          .buttons-background-gradient {
            min-width: 20px;
          }
        }
      }

      &.disabled {
        color: $font-color-lighter;
        cursor: not-allowed;

        td.name {
          color: $font-color-lighter;
        }
      }

      td {
        &:first-child {
          color: #000;
          @extend .table-first-column-shadow;
        }
        &:last-child {
          border-right: rgba(0, 0, 0, 0) 1px solid;
        }

        &.clickable {
          color: var($--main-theme-color);
        }

        &.capid {
          color: var($--main-theme-color);
        }

        &.kitId {
          color: var($--main-theme-color);
        }

        &.deviceId {
          color: var($--main-theme-color);
        }
      }

      & > td {
        vertical-align: middle !important;
        padding: 12px 50px 12px 15px;
        color: #4f4f4f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: rgba(0, 0, 0, 0) 1px solid;
        border-right: 1px solid rgba(0, 0, 0, 0.15);

        .td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .data-loading {
          position: relative;
          background-color: #f5f5f5;
          overflow: hidden;
          width: 100%;
          height: 24px;
          border-radius: 15px;
        }
        .data-loading::after {
          display: block;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          transform: translateX(-100%);
          animation: 2s loading linear 0.5s infinite;
          background: linear-gradient(90deg, transparent, #ededed, transparent);
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          transform: translateX(-100%);
          z-index: 1;
        }
        
        &.charge {
          text-align: left;
        }

        &.link,
        &.username,
        &.name {
          color: $color_td_name;
          font-weight: 400;
        }

        &.DeviceSN {
          color: $color_td_name;
          font-weight: 400;
        }

        .averageText {
          display: inline-block;
        }

        div.warning {
          color: var($--main-theme-color);
        }

        p {
          margin: 0;
        }

        .average {
          width: 48px;
          height: 10px;
          background-color: #C74382;
          border-radius: 5px;
          display: inline-block;
          margin-left: 20px;

          &.disabled {
            background-color: #dfdfdf;
          }

          .perfect {
            height: 10px;
            background-color: #74B236;
            width: 50%;
            border-radius: 5px;
          }

          .active {
            height: 10px;
            background-color: #74B236;
            width: 50%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            &:after {
              content: " ";
              width: 2px;
              height: 10px;
              background-color: #fff;
              display: block;
              float: right;
            }
          }
        }

        &.buttons {
          text-align: center;

          button,
          [role="button"] {
            opacity: 1;
          }
        }

        &:after {
          background-color: transparent;
          content: " ";
          transition: background-color $animation-swift-out;
        }
      }
      a {
        color: $color-brand;
        &:hover {
          color: $font-color-base;
        }
      }

      button,
      [role="button"] {
        height: 30px;
        opacity: 0;
        width: auto;
        min-width: auto;

        &.delete {
          width: 26px;
          height: 26px;
          margin-top: -4px;
        }

        & + button,
        & + [role="button"] {
          margin-left: 13px;
        }
      }

      .buttons {
        display: none;
        height: 50px;
        max-height: 50px;
        button,
        [role="button"] {
          opacity: 1;
        }
      }

      &:hover {
        & > td {
          border-top: var($--main-theme-color) 1px solid;
          border-bottom: var($--main-theme-color) 1px solid;
          background: var($--opaque-main-color-7);
          &:first-child {
            border-left: var($--main-theme-color) 1px solid;
          }
          &:last-of-type {
            border-right: var($--main-theme-color) 1px solid;
          }
          &:after {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            // background-color: $color-background-light-hover;
            content: " ";
            z-index: -1;
            height: 52px;
          }

          &.buttons {
            button,
            [role="button"] {
              opacity: 1;
            }
          }

          &:first-child:after {
            border-top-left-radius: 13px;
            border-bottom-left-radius: 13px;
          }

          &:last-child:after {
            border-top-right-radius: 13px;
            border-bottom-right-radius: 13px;
          }
        }

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: row;
          column-gap: 10px;
          min-height: 100%;
          background-color: var(--opaque-main-color-7);
          border-top: var(--main-theme-color) 1px solid;
          border-bottom: var(--main-theme-color) 1px solid;
          button,
          [role="button"] {
            opacity: 1;
          }
        }

        &:first-child {
          & > td {
            &:after {
              top: 11px;
            }
          }
        }
      }

      .column.adherence {
        .low {
          color: #e71e5b;
        }

        .high {
          color: #4ec02b;
        }
      }

      .icon-and-text-container {
        display: flex;
        align-items: center;
        .icon-container {
          height: 24px;
          width: 24px;
          min-width: 24px;
          margin-right: 13px;
          position: relative;
          border: none;
          &.with-border {
            border-radius: 20px;
            outline: 1px solid #bababa;
          }
          &.no-margin {
            margin: 0;
          }

          &.tick {
            background-color: #74b236;
            -webkit-mask: url("../images/rx/icon_tick.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
            mask: url("../images/rx/icon_tick.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
            mask-composite: exclude;
            &.grey {
              background-color: #bababa;
            }
          }
        
          &.hold {
            background: url("../images/rx/icon_hold.svg") no-repeat center/50%;
            border: 1px solid #bababa;
          }
        
          &.pending {
            background-color: #CCCCCC;
            -webkit-mask: url("../images/rx/icon_tick.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
            mask: url("../images/rx/icon_tick.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
            mask-composite: exclude;
          }
        
          &.notes {
            background: url("../images/rx/icon_Notes.svg") no-repeat center/50%;
            border: 1px solid #bababa;
          }

          &.alert {
            background-color: $color-error;
            -webkit-mask: url("../images/rx/Icon_Error.svg") no-repeat center/cover;
            mask: url("../images/rx/Icon_Error.svg") no-repeat center/cover;
            mask-composite: exclude;
          }

          &.envelope {
            background: url("../images/rx/Icon_Envelope.svg") no-repeat center/contain;
            width: 35px;
            min-width: 35px;
            &.unread::after {
              content: " ";
              width: 10px;
              height: 10px;
              position: absolute;
              background-color: #DA547D;
              border-radius: 5px;
              top: -1px;
              right: -1px;
            }
          }

          &.delete {
            background: url("../images/closeIcon.svg") no-repeat center/cover;
            border: 1px solid #bababa;
          }

          .icon-with-border {
            height: 24px;
            width: 24px;
            min-width: 24px;
            border-radius: 20px;
            &.dollar-on {
              background-color: $color-error;
              -webkit-mask: url("../images/rx/dollaricon.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
              mask: url("../images/rx/dollaricon.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
              mask-composite: exclude;
            }
            &.dollar-off {
              background-color: #bababa;
              -webkit-mask: url("../images/rx/dollaricon.svg") no-repeat center/45%;
              mask: url("../images/rx/dollaricon.svg") no-repeat center/45%;
            }
            &.pill-on {
              background-color: $color-error;
              -webkit-mask: url("../images/rx/vitals/Icon_Pill.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
              mask: url("../images/rx/vitals/Icon_Pill.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
              mask-composite: exclude;
            }
            &.pill-off {
              background-color: #bababa;
              -webkit-mask: url("../images/rx/vitals/Icon_Pill.svg") no-repeat center/contain;
              mask: url("../images/rx/vitals/Icon_Pill.svg") no-repeat center/contain;
            }
            &.heart-on {
              background-color: $color-error;
              -webkit-mask: url("../images/rx/heart.svg") no-repeat center/89%, linear-gradient(#fff 0 0);
              mask: url("../images/rx/heart.svg") no-repeat center/89%, linear-gradient(#fff 0 0);
              mask-composite: exclude;
            }
            &.heart-off {
              background-color: #bababa;
              -webkit-mask: url("../images/rx/heart.svg") no-repeat center/90%;
              mask: url("../images/rx/heart.svg") no-repeat center/90%;
            }
          }
        }
      }
    }
  }

  .icon-container {
    width: 38px;
    height: 38px;
    min-width: 38px;
    border: 1px solid transparent;
    border-radius: 100px;
    background-color: #fff;
    // &:hover:not(.disabled) {
    //   border: 1px solid transparent;
    // }
    &:last-child {
      margin-right: 5px;
    }
  }

  .icon {
    width: 38px;
    height: 38px;
    border-radius: 100px;
    min-width: 38px;
    background-color: var($--main-theme-color);
    transform: translate(-1px, -1px);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
    &.disabled {
      pointer-events: none;
    }
    &.edit {
        -webkit-mask: url("../images/rx/edit.svg") no-repeat center/45%;
        mask: url("../images/rx/edit.svg") no-repeat center/45%;
        cursor: pointer;
        &:hover, &.highlighted {
          box-shadow: unset;
          -webkit-mask: url("../images/rx/edit.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
          mask: url("../images/rx/edit.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
          mask-composite: exclude;
        }
    }
    &.delete, &.highlighted {
        -webkit-mask: url("../images/closeIcon.svg") no-repeat center/contain;
        mask: url("../images/closeIcon.svg") no-repeat center/contain;
        cursor: pointer;
        &:hover {
          box-shadow: unset;
          -webkit-mask: url("../images/closeIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
          mask: url("../images/closeIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
          mask-composite: exclude;
        }
    }
    &.enroll, &.highlighted {
      -webkit-mask: url("../images/actions/enroll.svg") no-repeat center/contain;
      mask: url("../images/actions/enroll.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/enroll.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/actions/enroll.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.activate {
      -webkit-mask: url("../images/actions/activate.svg") no-repeat center/contain;
      mask: url("../images/actions/activate.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/activate.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/actions/activate.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.endDate {
      -webkit-mask: url("../images/actions/EndDate.svg") no-repeat center/contain;
      mask: url("../images/actions/EndDate.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/EndDate.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/actions/EndDate.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.cancelEnrollment {
      -webkit-mask: url("../images/actions/cancelEnrollment.svg") no-repeat center/contain;
      mask: url("../images/actions/cancelEnrollment.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/cancelEnrollment.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/actions/cancelEnrollment.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.register {
      -webkit-mask: url("../images/actions/Register.svg") no-repeat center/contain;
      mask: url("../images/actions/Register.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/Register.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/actions/Register.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.download {
      -webkit-mask: url("../images/actions/action_download.svg") no-repeat center/contain;
      mask: url("../images/actions/action_download.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/action_download.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/actions/action_download.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.preview {
      -webkit-mask: url("../images/visibilityIcon.svg") no-repeat center/contain;
      mask: url("../images/visibilityIcon.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/visibilityIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/visibilityIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.refresh {
      -webkit-mask: url("../images/refresh-reset.svg") no-repeat center/45%;
      mask: url("../images/refresh-reset.svg") no-repeat center/45%;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/refresh-reset.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
        mask: url("../images/refresh-reset.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.message {
      -webkit-mask: url("../images/rx/messageIcon.svg") no-repeat center/75%;
      mask: url("../images/rx/messageIcon.svg") no-repeat center/75%;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/rx/messageIcon.svg") no-repeat center/75%, linear-gradient(#fff 0 0);
        mask: url("../images/rx/messageIcon.svg") no-repeat center/75%, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }

    &.addMedication {
      -webkit-mask: url("../images/rx/add_medication_icon.svg") no-repeat center/75%;
      mask: url("../images/rx/add_medication_icon.svg") no-repeat center/75%;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/rx/add_medication_icon.svg") no-repeat center/75%, linear-gradient(#fff 0 0);
        mask: url("../images/rx/add_medication_icon.svg") no-repeat center/75%, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.plus {
      -webkit-mask: url("../images/plus.svg") no-repeat center/55%;
      mask: url("../images/plus.svg") no-repeat center/55%;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/plus.svg") no-repeat center/55%, linear-gradient(#fff 0 0);
        mask: url("../images/plus.svg") no-repeat center/55%, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.invite {
      -webkit-mask: url("../images/actions/PortalAccess.svg") no-repeat center/contain;
      mask: url("../images/actions/PortalAccess.svg") no-repeat center/contain;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/PortalAccess.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask: url("../images/actions/PortalAccess.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
    &.launch {
      -webkit-mask: url("../images/actions/rocket_launch.svg") no-repeat center/55%;
      mask: url("../images/actions/rocket_launch.svg") no-repeat center/55%;
      cursor: pointer;
      &:hover, &.highlighted {
        box-shadow: unset;
        -webkit-mask: url("../images/actions/rocket_launch.svg") no-repeat center/55%, linear-gradient(#fff 0 0);
        mask: url("../images/actions/rocket_launch.svg") no-repeat center/55%, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
  }  
}

table.invited_patients {
  thead {
    th {
      width: 20%;
    }
  }
}

table.study-patients {
  thead {
    .column {
      &.id {
        width: 19%;
      }

      &.name {
        width: 19%;
      }

      &.dob {
        width: 19%;
      }

      &.adherence {
        width: 19%;
      }

      &.missed {
        width: 14%;
      }

      &.buttons {
        width: 10%;
      }
    }
  }
}

table.invited_patients {
  thead {
    .column {
      &.username {
        width: 24%;
      }
      &.email {
        width: 26%;
      }
      &.adherence {
        width: 22%;
      }
      &.study {
        width: 24%;
      }
      &.approved_by_patient {
        width: 10%;
      }
      &.buttons {
        width: 8%;
      }
    }
  }
}

table.studies {
  thead {
    .column {
      &.study-name {
        width: 24%;
      }

      &.patient_count {
        width: 26%;
      }

      &.adherence {
        width: 22%;
      }

      &.missed {
        width: 20%;
      }

      &.buttons {
        width: 8%;
      }
    }
  }
}

table.spirometry-results {
  tbody {
    tr { 
      height: 42px;
      td {
        text-align: right;
        padding: 8px 20px 8px 15px;
        &:first-child {
          text-align: left;
        }
      }
    }
  }
  thead {
    tr { 
      height: 64px;
      th {
        text-align: right;
        padding: 8px 20px 8px 15px;
        &:first-child {
          text-align: left;
        }
      }
    }
  }
}

table.spirometry-table, table.dashboard-table {
  tbody {
    tr {
      &.selected {
        background-color: $selected-row-background-color;
        td:first-child {
          background-color: $selected-row-background-color-no-opacity;
        }
        &:hover {
          &:nth-child(odd) {
            td {        
              &:first-child {
                border-left: $selected-hovered-row-background-color-no-opacity 1px solid;
                background: $selected-hovered-row-background-color-no-opacity;
              }
            }
          }
    
          &:nth-child(even) {
            td {        
              &:first-child {
                border-left: $selected-hovered-row-background-color-no-opacity 1px solid;
                background: $selected-hovered-row-background-color-no-opacity;
              }
            }
          }
        }
      }
    }
  }
}

table.table {
  &.patientsToCall-table {
    tbody {
      padding: 0 21px;
      tr {
        td {
          .red {
            color: #AB3439;
          }
          &.vitals {
            color: $color-error;
          }
        }
      }
    }
  }
}

table .table-body.fade-in {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
  cursor: default;
}

.marginBottomTable {
  margin-bottom: 20px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.table-pagination {
  padding-top: 15px;
  display: flex;
  align-items: center;

  input,
  .pagination-spinner-container {
    border: solid 1px #bababa;
    padding: 0;
    vertical-align: middle;
    margin: 0 15px;
    width: 100px;
    height: 36px;
    border-radius: 13px;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
    display: inline-block;
    text-align: center;
    line-height: 2.4;
    position: relative;
    color: black;

    &::placeholder {
      opacity: 0.8;
    }
  }

  .footerButtonContainer {
    width: 34px;
    height: 34px;
    min-width: 34px;
    border: 1px solid #d9d9d9;
    border-radius: 18px;
    margin: 0;
    padding: 0;
    &:hover:not(.disabled) {
      border: 1px solid transparent;
    }
  }

  button, [role="button"] {
    width: 34px;
    height: 34px;
    border-radius: 18px;
    min-width: 34px;
    margin: 0;
    padding: 0;
    transform: translate(-2px, -1px);
    background-color: var($--main-theme-color);
    -webkit-mask: url("../images/leftArrow.svg") no-repeat center/contain;
    mask: url("../images/leftArrow.svg") no-repeat center/contain;
    &:hover {
      box-shadow: unset;
      -webkit-mask: url("../images/leftArrow.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
      mask: url("../images/leftArrow.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      transform: translate(-2px, -1px);
    }

    &.disabled {
      background-color: #000;
      pointer-events: none;
    }

    &.next {
      transform: translate(-1px, -1px) rotate(180deg);
    }

    &:after {
      content: unset;
    }
  }

  &.empty {
    height: 68px;
  }

  &.hide-paging {
    height: 68px;
    display: none;
  }
}

.table-first-column-shadow {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
  clip-path: inset(0px -15px 0px 0px);
}
.table-header-horizontal-shadow {
  background: linear-gradient(#d9d9d9 0px, rgb(245, 245, 245) 5px, rgba(245, 245, 245, 1) 100%);
}

.list-table {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  .list-table-border {
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    .widget-scrollable {
      border-radius: 20px;
    }
  }
}
