@import "../../containers/Modal/Wizard.scss";

.wizard.edit-cohort {
    max-width: 50vw;
}
.cohort-patient-page {
  max-height: calc($wizard-content-height - $content-padding * $scale-koef - 2 * $content-block-padding - 40px);
  display: flex;
  flex-direction: column;
  .table-container {
    min-width: 0;
  }
  .spinner-container {
    width: 100%;
    min-height: 150px;
  }
}